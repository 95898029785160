import React from "react";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import Bots from "../../components/Bots";
import Subscribe from "../../components/Subscribe";
import Footer from "../../components/Footer";
import Logo from "../../img/logo-white.png";

const BotsPage = () => {
  return (
    <>
      <Header Logo={Logo} />
      <PageTitle
        PageTitle={
          <div>
            Uw <span>bots</span>
          </div>
        }
      />
      <Bots />
    </>
  );
};
export default BotsPage;
