import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";

import "../../flaticon/flaticon.css";
import "./style.css";

const Services = () => {
  return (
    <>
      <section className="service-section section_100">
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={12}>
              <div className="section-title  ">
                <h2>
                  Onze producten
                </h2>
              </div>
            </Col>
            <Col lg={7} md={12}>
              <div className="section-para ">
                <p>
                  Producten en Diensten van NovaIQ, een pallet aan oplossingen en diensten om uw organisatie te versterken.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6}>
              <div className="service-box">
                <div className="service-icon">
                  <i className="flaticon-chip" />
                </div>
                <div className="service-title">
                  <h3>Robots (RPA)</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Onze kern en passie. Geavanceerde software automatiseert repetitieve taken, bespaart tijd en verhoogt de efficiëntie.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-chip" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-picture" />
                </div>
                <div className="service-title">
                  <h3>Data-analyse en -verwerking</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Automatiseer de verwerking van grote hoeveelheden data voor snellere, nauwkeurigere resultaten.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-picture" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-box" />
                </div>
                <div className="service-title">
                  <h3>Systeemintegratie</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Onze tools zorgen voor een naadloze integratie van onze oplossingen met uw bestaande systemen.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-box" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-mobile" />
                </div>
                <div className="service-title">
                  <h3>API-ontwikkeling</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Maatwerkoplossingen voor specifieke bedrijfsbehoeften.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-mobile" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="service-section section_100">
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={12}>
              <div className="section-title  ">
                <h2>
                  Onze diensten
                </h2>
              </div>
            </Col>
            <Col lg={7} md={12}>
              <div className="section-para ">
                <p>
                  Producten en Diensten van NovaIQ, een pallet aan oplossingen en diensten om uw organisatie te versterken.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6}>
              <div className="service-box">
                <div className="service-icon">
                  <i className="flaticon-chip" />
                </div>
                <div className="service-title">
                  <h3>Analyse</h3>
                </div>
                <div className="service-desc">
                  <p>
                  We analyseren uw huidige processen en identificeren optimalisatiemogelijkheden.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-chip" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-picture" />
                </div>
                <div className="service-title">
                  <h3>Strategische Planning</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Ontwikkeling van een lange termijn automatiseringsstrategie.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-picture" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-box" />
                </div>
                <div className="service-title">
                  <h3>Implementatie</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Wij ondersteunen voor een soepele implementatie van onze software in uw bedrijf. Techniek ondersteunend aan de mens.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-box" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-mobile" />
                </div>
                <div className="service-title">
                  <h3>Medewerkerstraining</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Wij bieden trainingen aan om uw team vertrouwd te maken met onze software.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-mobile" />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-mobile" />
                </div>
                <div className="service-title">
                  <h3>Support</h3>
                </div>
                <div className="service-desc">
                  <p>
                  Ons team staat klaar om u te ondersteunen bij eventuele vragen of uitdagingen.
                  </p>
                  <Link className="btn-simple mt-3" to="/single-service">
                    Read More <FaLongArrowAltRight />
                  </Link>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-mobile" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Services;
