import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import img1 from "../../img/about-shape.png";
import img2 from "../../img/about_robot.jpg";
import img3 from "../../img/about_maatwerk.jpg";

import "./style.css";

const About = () => {
  return (
    <>
      <section className="about-section">
        {/* <div className="about-top-shape">
          <img src={img1} alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src={img1} alt="about shape" />
        </div> */}
        <div className="about-top section_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-right">
                  <h3>
                    Wij maken robots: <br/>Transformeer je bedrijf met RPA
                  </h3>
                  <p>
                    Bij NovaIQ creëren we digitale werknemers die jouw bedrijfsprocessen transformeren. 
                    Met Robot Process Automation (RPA) nemen we repetitieve taken over, zodat jouw bedrijf kan schitteren in strategisch werk. 
                    Ontdek de magie van geautomatiseerde efficiëntie.
                  </p>
                  <Link to="/about" className="theme-btn mt-4">
                    lees meer
                    <span>
                      <FaChevronRight />
                    </span>
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-right" data-aos="fade-left">
                  <img src={img2} alt="about" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-bottom section_b_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-right  " data-aos="fade-right">
                  <img src={img3} alt="about" />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-left">
                  <h3>
                  Maatwerk voor Jouw Bedrijf
                  </h3>
                  <p>
                  Bij NovaIQ begrijpen we dat jouw bedrijf uniek is. Daarom bieden wij maatwerk RPA-oplossingen die naadloos passen bij jouw specifieke behoeften. Optimaliseer efficiëntie, bespaar kosten en ontdek de kracht van afgestemde automatisering.
                  </p>
                  <Link to="/about" className="theme-btn mt-4">
                    lees meer
                    <span>
                      <FaChevronRight />
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default About;
