import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import "../../flaticon/flaticon.css";
import img1 from "../../img/about-post-1.jpeg";
import img2 from "../../img/about-post-2.jpeg";
import img3 from "../../img/about-post-3.jpeg";
import shape1 from "../../img/about-shape.png";
import "./style.css";

const AboutPages = () => {
  return (
    <>
      <section className="about-page-section section_100">
        <Container>
          {/* <Row>
            <Col lg={12}>
              <div className="section-title ">
                <h2>
                  World's <span>Leading</span> RPA <br /> Company
                </h2>
              </div>
            </Col>
          </Row> */}
          <Row className="align-items-center">
            {/* <Col lg={{ span: 4, offset: 1 }}>
              <div className="about-page-left ">
                <h2 className="mr-5">
                  <span className="mark-text">40</span>Years’ Experience in IT
                </h2>
              </div>
            </Col> */}
            <Col lg={8}>
              <div className="about-page-text ">
                
                <p>
                Ons verhaal begint bij het samenkomen van vier individuen, elk met een achtergrond en specialisme in de ICT branche. 
                Wat ons bij elkaar bracht is onze drive voor ondernemen en onze gemeenschappelijke passie: het creëren van slimme, doordachte oplossingen die zowel administratief werk verlicht als organisaties versterkt.
                </p>
                <br/>
                <p>

                NovaIQ is ontstaan uit de samensmelting van twee krachtige concepten: NOVA en IQ. 'NOVA', Latijns voor 'nieuw', symboliseert onze voortdurende drang naar vernieuwing en ontdekking. 
                Het staat voor onze bereidheid om gevestigde patronen te ontdekken, te doorbreken en frisse, innovatieve ideeën te brengen in alles wat we doen. 
                'IQ', dat staat voor intelligentie, weerspiegelt onze toewijding aan slimme oplossingen die niet alleen effectief zijn, maar ook intuïtief en toegankelijk.
                </p>
                <br/>
                <p>
                De oprichters van NovaIQ brengen een divers pallet aan kennis, ervaring en perspectieven met zich mee. 
                Deze diversiteit is onze kracht, waardoor we unieke invalshoeken kunnen bieden en uitdagingen vanuit verschillende perspectieven kunnen benaderen. 
                Samen vormen we een team dat gedreven wordt door creativiteit, expertise en een niet-aflatende passie om goede oplossingen te leveren. 
                We zijn ook zelfkritisch en dagen elkaar uit tot verbeteren én anders te denken. 
                </p>
                <br/>
                <p>Onze missie is eenvoudig: het verlichten van werk en het versterken van organisaties. 
                Wij geloven dat de beste oplossingen niet alleen technisch slim zijn, maar ook menselijk en toegankelijk. 
In elk project dat we ondernemen, streven we ernaar om het werk eenvoudiger, efficiënter en plezieriger te maken. 
Dit terwijl we de mensen die onze oplossingen gebruiken, in staat stellen om hun potentieel te maximaliseren.</p>

                <br/>

                <p>
                Bij NovaIQ draait alles om de cohesie tussen mens en technologie en het brengen van toegevoegde waarde. 
                We combineren geavanceerde technische kennis met een begrip van menselijke behoeften en processen. 
                Deze aanpak stelt ons in staat om niet alleen te reageren op de huidige behoeften van onze klanten, maar ook mee te denken in toekomstige uitdagingen en kansen om te ondersteunen maximale toegevoegde waarde te brengen.
                </p>
                <br/>
                <p>
                Wij zijn NovaIQ - waar innovatie en intelligentie samenkomen om werk te verlichten en organisaties te versterken.
                Welkom in onze wereld van slimme oplossingen.
                
                </p>
                <Link to="/contact" className="theme-btn mt-4">
                  Lees meer
                  <span>
                    <FaChevronRight />
                  </span>
                </Link>
                

                
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about-post-section section_b_70">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <div className="single-about-post ">
                <div className="about-post-image">
                  <img src={img1} alt="img" />
                </div>
                <div className="about-post-text">
                  <h3>If you're having trouble coming up with</h3>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="single-about-post  ">
                <div className="about-post-image">
                  <img src={img2} alt="img" />
                </div>
                <div className="about-post-text">
                  <h3>what are those Steps to be a Successful developer</h3>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="single-about-post  ">
                <div className="about-post-image">
                  <img src={img3} alt="img" />
                </div>
                <div className="about-post-text">
                  <h3>details about Apple’s new iPad Pro models</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="timeline-area section_100">
        <div className="about-top-shape">
          <img src={shape1} alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src={shape1} alt="about shape" />
        </div>
        <Container>
          <Row>
            <Col md={12}>
              <div className="ui-timline-container">
                <div className="ui-timeline">
                  <div className="tl-item">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-caption">
                          <p>
                            Our <br /> Journey
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tl-item  ">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-time">2020</div>
                        <div className="tl-icon" />
                        <div className="tl-content">
                          <h3 className="tl-tile">Startup Consultation</h3>
                          <h4 className="tl-date">March 26</h4>
                          <p>
                            Interdum iusto pulvinar elit.accusantium debitis
                            voluptatem dolore excepturi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tl-item alt  ">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-time">2019</div>
                        <div className="tl-icon" />
                        <div className="tl-content">
                          <h3 className="tl-tile">Audit &amp; assurance</h3>
                          <h4 className="tl-date">February 19</h4>
                          <p>
                            Interdum iusto pulvinar elit.accusantium debitis
                            voluptatem dolore excepturi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tl-item  ">
                    <div className="tl-body">
                      <div className="tl-entry">
                        <div className="tl-time">2018</div>
                        <div className="tl-icon" />
                        <div className="tl-content">
                          <h3 className="tl-tile">Expert Team Mates</h3>
                          <h4 className="tl-date">April 04</h4>
                          <p>
                            Interdum iusto pulvinar elit.accusantium debitis
                            voluptatem dolore excepturi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutPages;
